import { memo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Container, AppBar, Stack, Typography } from '@mui/material';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
import NotificationsPopover from '../header/NotificationsPopover';
import AccountPopover from '../header/AccountPopover';
import Searchbar from '../header/Searchbar';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create('top', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  width: '100%',
  position: 'fixed',
  zIndex: theme.zIndex.appBar,
  boxShadow: 'none',
  top: 0,
  backgroundColor: theme.palette.background.default,
}));

const StackStyle = styled(Stack)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: '5px 10px',
  display: 'flex',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  const isVerified = false;
  return (
    <RootStyle>
      <Container maxWidth={false} sx={{ padding: '0px 80px 0px 40px !important', borderBottom: 'solid 1px #D9D9D9' }}>
        <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
          <NavSectionHorizontal />
          <Stack direction="row" alignItems="center" padding={'16px 0'} spacing={{ xs: 0.5, sm: 1.5 }}>
            <Searchbar />
            <NotificationsPopover />
            <AccountPopover />
          </Stack>
        </Stack>
      </Container>
      {isVerified && (
        <StackStyle direction={'row'}>
          <Typography>
            Verify your email to complete your account setup{' '}
            <Typography variant="span" fontWeight={700} sx={{ cursor: 'pointer' }}>
              Click here
            </Typography>
          </Typography>
        </StackStyle>
      )}
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
