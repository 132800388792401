/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import React, { memo, useEffect } from 'react';
// @mui
import { Stack, Tab, Tabs } from '@mui/material';
//
import Image from 'src/components/Image';
import { useLocation, useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const tabStyle = {
  '.MuiTabs-flexContainer': {
    height: '100%',
    '.MuiButtonBase-root': {
      fontWeight: 400,
      fontSize: '18px',
      color: '#637381',
      '&.Mui-selected': {
        fontWeight: 700,
        color: '#212B36',
      },
    },
  },
};

NavSectionHorizontal.propTypes = {
  // navConfig: PropTypes.array,
};

function NavSectionHorizontal() {
  const TABS = [
    { value: PATH_DASHBOARD.general.app, label: 'Dashboard' },
    { value: PATH_DASHBOARD.general.opportunities, label: 'Opportunities' },
    // { value: PATH_DASHBOARD.general.profile, label: 'Profile' },
    { value: PATH_DASHBOARD.general.passbook, label: 'Passbook' },
  ];
  const [value, setValue] = React.useState(PATH_DASHBOARD.general.app);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(location.pathname !== value) {
      setValue(location.pathname);
    }
  }, [location.pathname])

  return (
    <Stack direction="row" justifyContent="center" alignItems={'center'} height={'100%'}>
      <Stack>
        <Image src={`/logo/logo_jrl.svg`} sx={{ width: 100, height: 45, ml: 2 }} />
      </Stack>
      <Stack direction="row" sx={{ ...hideScrollbar, px: 7, height: '80px' }}>
        <Tabs
          allowScrollButtonsMobile
          value={value}
          onChange={(e, newVal) => navigate(newVal)}
          variant="scrollable"
          scrollButtons="auto"
          sx={tabStyle}
        >
          {TABS.map((tab) => (
            <Tab disableRipple key={tab.value} value={tab.value} label={tab.label} sx={{ p: '0 5px' }} />
          ))}
        </Tabs>
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
